import { IState } from "~/store/types";
import { ILoaderOption } from "~/loader/types";
import { AxiosRequestConfig } from "axios";
import { Core } from "~/core/Core";
import { _omitBy } from "~/utils/lodash";
import { CONFIG } from "~/constants/config";
import { API_TYPE } from "~/constants/apiTypes";

export const isChangeWithTrue = (
  value: boolean,
  oldValue: boolean
): boolean => {
  return oldValue !== value && value;
};

export const isChangeWithFalse = (
  value: boolean,
  oldValue: boolean
): boolean => {
  return oldValue !== value && !value;
};

export const getRequestObjectOptions = (
  ctx: IState | any,
  options: ILoaderOption,
  $cookies: any = null
): AxiosRequestConfig => {
  let apiType = options.apiType;
  let token = "";

  if (apiType === API_TYPE.MKT) {
    token =
      Core.MKTAPIOptions(ctx)?.headers?.token ||
      $cookies?.get("me")?.token ||
      "";
    return {
      ...Core.MKTAPIOptions(ctx),
      params: {
        limit: CONFIG.LIMIT_PER_PAGE,
        ...(options.params && _omitBy(options.params)),
      },
      headers: {
        ...Core.MKTAPIOptions(ctx).headers,
        ...(token && { Authorization: token }),
        ...(options.headers && options.headers),
      },
      ...options.callback,
    };
  } else if (apiType === API_TYPE.MKT_CMS) {
    token =
      Core.MKTCMSAPIOptions(ctx)?.headers?.token ||
      $cookies?.get("me")?.token ||
      "";
    return {
      ...Core.MKTCMSAPIOptions(ctx),
      params: {
        limit: CONFIG.LIMIT_PER_PAGE,
        ...(options.params && _omitBy(options.params)),
      },
      headers: {
        ...Core.MKTCMSAPIOptions(ctx).headers,
        ...(options.headers && options.headers),
        ...(token && { Authorization: token }),
      },
      ...options.callback,
    };
  } else if (apiType === API_TYPE.ECOM_CMS || apiType === API_TYPE.ECOM) {
    token =
      Core.ECOMAPIOptions(ctx)?.headers?.token ||
      $cookies?.get("me")?.token ||
      "";
    return {
      ...Core.ECOMAPIOptions(ctx),
      params: {
        limit: CONFIG.LIMIT_PER_PAGE,
        ...(options.params && _omitBy(options.params)),
      },
      headers: {
        ...Core.ECOMAPIOptions(ctx).headers,
        ...(options.headers && options.headers),
        ...(token && { Authorization: token }),
      },
      ...options.callback,
    };
  } else {
    return {
      ...Core.MKTCONNECTAPIOptions(ctx),
      params: {
        limit: CONFIG.LIMIT_PER_PAGE,
        ...(options.params && _omitBy(options.params)),
      },
      headers: {
        ...Core.MKTCONNECTAPIOptions(ctx).headers,
        ...(options.headers && options.headers),
        ...(token && { Authorization: token }),
      },
      ...options.callback,
    };
  }
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getAliasFromText = (str: string): string => {
  if (str) {
    const regexpSpacialLetter: RegExp =
      /[\_|\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\s]+/g;
    return str
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "")
      .replace(/[ก-๙]/g, "")
      .replace(regexpSpacialLetter, "");
  }
  return "";
};

export const keyvalue = (data, label?, value?, subLabel?) => {
  let lab = label ? data[label] : data;
  const val = value ? data[value] : data;
  const sub = subLabel ? data[subLabel] : "";

  if (sub && sub.length > 0) {
    lab = `${lab} (${sub})`;
  }
  return {
    label: lab || "",
    value: val || "",
  };
};

export const toPercent = (
  total: number | undefined,
  current: number | undefined
): number => {
  if (!total || !current) {
    return 0;
  }
  return (current / total) * 100;
};

export const genString = (length: number = 5) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const convertValueToInt = (value: string | number) => {
  if (typeof value === "number") {
    return value;
  } else {
    return parseInt(value);
  }
};

// export const findURLsInMessage = () => {
//   if svc.urlRegex == nil {
// 		r, _ := regexp.Compile(`(HTTP|HTTPS|http|https):\/\/?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}([-a-zA-Z0-9\p{L}\p{M}@:%_\+.~#?&//=]*)`)
// 		svc.urlRegex = r
// 	}
// 	return svc.urlRegex
// }

export const replaceURLsWithString = (
  message: string,
  replaceString: string
) => {
  const urlRegex =
    /(HTTP|HTTPS|http|https):\/\/?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}([-a-zA-Z0-9\p{L}\p{M}@:%_\+.~#?&//=]*)/g;
  const fileExts = [".jpg", ".jpeg", ".png", ".webp", ".gif", ".svg", ".tiff"]; // ignore image files
  return message.replace(urlRegex, function (url) {
    let isIgnoreURL = false;
    for (let fileExt of fileExts) {
      if (url.toLowerCase().endsWith(fileExt)) {
        isIgnoreURL = true;
        break;
      }
    }

    if (isIgnoreURL) {
      return url;
    } else {
      return replaceString;
    }
  });
};

export const hasUnicode = (message: string) => {
  return /[^\u0000-\u007f]/.test(message);
};

export const isSameDomain = (url1: string, url2: string): boolean => {
  try {
    const firstUrl = new URL(url1);
    const secondUrl = new URL(url2);

    return firstUrl.hostname === secondUrl.hostname;
  } catch (error) {
    console.error("Invalid URL(s):", error);
    return false;
  }
}
