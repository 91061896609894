import { CONFIG } from "./config"

let MARKETING_THEME = {
  name: 'Marketing Automation',
  alias: 'marketing',
  description: 'Right Message, Right Time',
  route: '/marketing',
  color: '#f1fbf9',
  avatar_color: '#37BEB0',
  theme: {
    primary: '#37BEB0',
    primaryLight: '#A4E5E0',
    primaryLighter: '#DBF5F0',
    secondary: '#0C6170',
    accent: '#5A5F5F',
    error: '#EB7458',
    info: '#82CDE3',
    success: '#71D879',
    warning: '#FFD257',
    background: '#F0F5F4',
    border: '#E6EBEA',
    group_primary: '#ADA2F0',
    ai_primary: '#FF6EA2'
  }
}

if (CONFIG.MARKETING_THEME == "BZB") {
  MARKETING_THEME = {
    name: '',
    alias: 'marketing',
    description: 'Right Message, Right Time',
    route: '/marketing',
    color: '#f1fbf9',
    avatar_color: '#F9A601',
    theme: {
      primary: '#F9A601',
      primaryLight: '#e8b720',
      primaryLighter: '#DBF5F0',
      secondary: '#494949',
      accent: '#5A5F5F',
      error: '#EB7458',
      info: '#82CDE3',
      success: '#71D879',
      warning: '#FFD257',
      background: '#F0F5F4',
      border: '#E6EBEA',
      group_primary: '#ADA2F0',
      ai_primary: '#FF6EA2'
    }
  }
}

const ECOMMERCE_THEME = {
  name: 'Ecommerce',
  alias: 'ecom',
  description: 'Sell your best product',
  route: '/ecom/members',
  color: '#faf3ff',
  avatar_color: '#aa76cb',
  theme: {
    primary: '#AA76CB',
    primaryLight: '#BCA3CC',
    primaryLighter: '#E7D8F0',
    secondary: '#531A76',
    accent: '#5A5F5F',
    error: '#EB7458',
    info: '#82CDE3',
    success: '#71D879',
    warning: '#FFD257',
    background: '#EEEBF0',
    border: '#E1DEE3',
    group_primary: '#ADA2F0',
    ai_primary: '#FF6EA2'
  }
}

const COUPON_PORTAL_THEME = {
  name: 'Coupon Portal',
  alias: 'coupon',
  description: 'Drive purchase with coupon',
  route: '/coupon',
  color: '#F8F9FF',
  avatar_color: '#8895FA',
  theme: {
    primary: '#8895FA',
    primaryLight: '#C0C7FA',
    primaryLighter: '#E8EBFF',
    secondary: '#1A2576',
    accent: '#5A5F5F',
    error: '#EB7458',
    info: '#82CDE3',
    success: '#71D879',
    warning: '#FFD257',
    background: '#F0F1F5',
    border: '#DEDFE3',
    group_primary: '#ADA2F0',
    ai_primary: '#FF6EA2'
  }
}

const AUTOPILOT_THEME = {
  name: 'Autopilot',
  alias: 'autopilot',
  description: 'Smart Drive, Safe Flight',
  route: '/autopilot',
  color: '#f1fbf9',
  avatar_color: '#37BEB0',
  theme: {
    primary: '#37BEB0',
    primaryLight: '#A4E5E0',
    primaryLighter: '#DBF5F0',
    secondary: '#0C6170',
    accent: '#5A5F5F',
    error: '#EB7458',
    info: '#82CDE3',
    success: '#71D879',
    warning: '#FFD257',
    background: '#F0F5F4',
    border: '#E6EBEA',
    group_primary: '#ADA2F0',
    ai_primary: '#FF6EA2'
  }
}

const CRM_THEME = {
  name: 'CRM',
  alias: 'crm',
  description: 'Sell your best product',
  route: CONFIG.PLATFORM_CRM,
  color: '#FAF3FF',
  avatar_color: '#AA76CB',
  theme: {
    primary: '#AA76CB',
    primaryLight: '#BCA3CC',
    primaryLighter: '#E7D8F0',
    secondary: '#531A76',
    accent: '#5A5F5F',
    error: '#EB7458',
    info: '#82CDE3',
    success: '#71D879',
    warning: '#FFD257',
    background: '#EEEBF0',
    border: '#E1DEE3',
    group_primary: '#ADA2F0',
    ai_primary: '#FF6EA2'
  }
}

const CHAT_CENTER_THEME = {
  name: 'Chat Center',
  alias: 'chat_center',
  description: 'Chat Center',
  route: CONFIG.PLATFORM_CHAT_CENTER,
  color: '#F8F9FF',
  avatar_color: '#8895FA',
  theme: {
    primary: '#8895FA',
    primaryLight: '#C0C7FA',
    primaryLighter: '#E8EBFF',
    secondary: '#1A2576',
    accent: '#5A5F5F',
    error: '#EB7458',
    info: '#82CDE3',
    success: '#71D879',
    warning: '#FFD257',
    background: '#F0F1F5',
    border: '#DEDFE3',
    group_primary: '#ADA2F0',
    ai_primary: '#FF6EA2'
  }
}

const SURVEY_THEME = {
  name: 'Survey',
  alias: 'Survey',
  description: 'Create a survey',
  route: CONFIG.PLATFORM_SURVEY,
  color: '#F1FBF9',
  avatar_color: '#F7993C',
  theme: {
    primary: '#F7993C',
    primaryLight: '#A4E5E0',
    primaryLighter: '#DBF5F0',
    secondary: '#0C6170',
    accent: '#5A5F5F',
    error: '#EB7458',
    info: '#82CDE3',
    success: '#71D879',
    warning: '#FFD257',
    background: '#F0F5F4',
    border: '#E6EBEA',
    group_primary: '#ADA2F0',
    ai_primary: '#FF6EA2'
  }
}

const SAI_THEME = {
  name: 'SAI',
  alias: 'SAI',
  description: 'Ask the Ai Agent',
  route: CONFIG.PLATFORM_SAI,
  color: '#FF6EA2',
  avatar_color: '#FF6EA2',
  theme: {
    primary: '#F7993C',
    primaryLight: '#A4E5E0',
    primaryLighter: '#DBF5F0',
    secondary: '#0C6170',
    accent: '#5A5F5F',
    error: '#EB7458',
    info: '#82CDE3',
    success: '#71D879',
    warning: '#FFD257',
    background: '#F0F5F4',
    border: '#E6EBEA',
    group_primary: '#ADA2F0',
    ai_primary: '#FF6EA2'
  }
}

const APOINTMENT_THEME = {
  name: 'Appointment',
  alias: 'Appointment',
  description: 'Appointment',
  route: CONFIG.PLATFORM_APPOINTMENT,
  color: '#026B9E',
  avatar_color: '#026B9E',
  theme: {
    primary: '#026B9E',
  }
}

const DEFAULT_THEME = {
  name: 'Default theme',
  description: 'I LOVE 3DS',
  route: '/',
  theme: {
    primary: '#5A5F5F',
    secondary: '#5A5F5F',
    accent: '#5A5F5F'
  }
}


let ALL_THEME: any[] = []

if (CONFIG.ENABLED_NEW_PLATFORM) {
  ALL_THEME = [ MARKETING_THEME ]
  if (CONFIG.PLATFORM_CRM) {
    ALL_THEME.push(CRM_THEME)
  }
  if (CONFIG.PLATFORM_CHAT_CENTER) {
    ALL_THEME.push(CHAT_CENTER_THEME)
  }
  if (CONFIG.PLATFORM_SURVEY) {
    ALL_THEME.push(SURVEY_THEME)
  }
  if (CONFIG.PLATFORM_SAI) {
    ALL_THEME.push(SAI_THEME)
  }
  if (CONFIG.PLATFORM_APPOINTMENT) {
    ALL_THEME.push(APOINTMENT_THEME)
  }
} else {
  ALL_THEME = [
    MARKETING_THEME,
    { ...ECOMMERCE_THEME, ...(CONFIG.PLATFORM_SWITCHER ?? "").includes('ecom') ? {} : {route : ""} },
    { ...COUPON_PORTAL_THEME, ...(CONFIG.PLATFORM_SWITCHER ?? "").includes('coupon') ? {} : {route : ""} },
    { ...AUTOPILOT_THEME, ...(CONFIG.PLATFORM_SWITCHER ?? "").includes('autopilot') ? {} : {route : ""} }
  ]
}

export { ALL_THEME };

export const ACTIVE_THEMES = CONFIG.PLATFORM_SWITCHER!

export const availableThemes = (): any[] => {
  const platformSwicher = CONFIG.PLATFORM_SWITCHER!
  return ALL_THEME.filter((theme => platformSwicher.includes(theme.alias)))
}

export const THEMES = {
  MARKETING: MARKETING_THEME,
  ECOMMERCE: ECOMMERCE_THEME,
  COUPON: COUPON_PORTAL_THEME,
  AUTOPILOT: AUTOPILOT_THEME,
  DEFAULT: DEFAULT_THEME
}
