import {CONFIG} from '~/constants/config'
import urlJoin from 'url-join'
import {IState} from '~/store/types'
import {TimeHelper} from "~/utils/TimeHelper";
import {API_TYPE} from "~/constants/apiTypes";

export class Core {
  static get config() {
    return CONFIG
  }

  static MKTAPIOptions = (ctx: IState | any, cookies?: any): any => {
    let token = false
    if (ctx.rootState) {
      token = ctx.rootState?.app?.me?.token || cookies?.get('me')?.token || false
    } else {
      token = ctx.app?.me?.token || cookies?.get('me')?.token || false
    }

    return {
      apiType: API_TYPE.MKT,
      baseURL: Core.config.MKT_API,
      headers: {
        ...(token && {Authorization: token}),
        'x-timestamp': TimeHelper.toUTC(TimeHelper.getCurrentDateTime()),
      }
    }
  }

  static MKTCONNECTAPIOptions = (ctx: IState | any, cookies?: any): any => {
    let token = false
    if (ctx.rootState) {
      token = ctx.rootState?.app?.me?.token || cookies?.get('me')?.token || false
    } else {
      token = ctx.app?.me?.token || cookies?.get('me')?.token || false
    }

    return {
      apiType: API_TYPE.MKT_CONNECT,
      baseURL: Core.config.MKT_CONNECT_API,
      headers: {
        ...(token && {Authorization: token}),
        'x-timestamp': TimeHelper.toUTC(TimeHelper.getCurrentDateTime()),
        'x-device': 'browser',
        'x-secret': CONFIG.API_SECRET,
      }
    }
  }

  static MKTCMSAPIOptions = (ctx: IState | any, cookies?: any): any => {
    let token = false
    if (ctx.rootState) {
      token = ctx.rootState?.app?.me?.token || cookies?.get('me')?.token || false
    } else {
      token = ctx.app?.me?.token || cookies?.get('me')?.token || false
    }

    // let customToken = "pam eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijk1Y2VmZjQzLTU2ZDUtNDc1Zi1hMDgxLTkyOTVjMTgwZjhkZCIsInVzZXJfaWQiOiJlMTMzMTJlZC05YTczLTQ1NWEtODdjZi1lNjMyNWMyYTZiMzUiLCJleHAiOjE2NzUwMTUwMDB9.9mTGNCeaJNGbTUXKAO6hH1UxWtFopODY7xo8p4GYL30"

    return {
      apiType: API_TYPE.MKT_CMS,
      baseURL: Core.config.MKT_CMS_API,
      headers: {
        // ...(token && {Authorization: token}),
        'x-timestamp': TimeHelper.toUTC(TimeHelper.getCurrentDateTime()),
        'x-device': 'browser',
        'x-secret': CONFIG.API_SECRET,
      }
    }
  }

  static ECOMAPIOptions = (ctx: IState | any, cookies?: any): any => {
    let token = false
    if (ctx.rootState) {
      token = ctx.rootState?.app?.me?.token || cookies?.get('me')?.token || false
    } else {
      token = ctx.app?.me?.token || cookies?.get('me')?.token || false
    }

    return {
      apiType: API_TYPE.ECOM,
      baseURL: Core.config.ECOM_API,
      headers: {
        ...(token && {Authorization: token}),
        'x-timestamp': TimeHelper.toUTC(TimeHelper.getCurrentDateTime()),
        'x-device': 'browser',
      }
    }
  }

  static ECOMCMSAPIOptions = (ctx: IState | any, cookies?: any): any => {
    let token = false
    if (ctx.rootState) {
      token = ctx.rootState?.app?.me?.token || cookies?.get('me')?.token || false
    } else {
      token = ctx.app?.me?.token || cookies?.get('me')?.token || false
    }

    return {
      apiType: API_TYPE.ECOM_CMS,
      baseURL: Core.config.ECOM_CMS_API,
      headers: {
        ...(token && {Authorization: token}),
        'x-timestamp': TimeHelper.toUTC(TimeHelper.getCurrentDateTime()),
        'x-device': 'browser',
      }
    }
  }

  static MKTAPI = (path: string): string => {
    return urlJoin(`${Core.config.MKT_API}`, path)
  }

  static MKTBACKENDAPI = (path: string): string => {
    return urlJoin(`${Core.config.MKT_BACKEND_API}`, path)
  }

  static MKTCMSAPI = (path: string): string => {
    return urlJoin(`${Core.config.MKT_CMS_API}`, path)
  }

  static MKTCONNECTAPI = (path: string): string => {
    return urlJoin(`${Core.config.MKT_CONNECT_API}`, path)
  }

  static ECOMCMSAPI = (path: string): string => {
    return urlJoin(`${Core.config.ECOM_CMS_API}`, path)
  }

  static ECOMAPI = (path: string): string => {
    return urlJoin(`${Core.config.ECOM_API}`, path)
  }

  static CRMAPI = (path: string): string => {
    return urlJoin(`${Core.config.CRM_API}`, path)
  }

  static MKTCONNECTMETRICAPI = (path: string): string => {
    return urlJoin(`${Core.config.MKT_CONNECT_METRIC_API}`, path)
  }
}
